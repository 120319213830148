window.onload = function(){ 
    document.getElementById('btn-open').onclick = function openContent() {
        var contentForm = document.getElementById('content-form');
        contentForm.classList.add('--open');

        var btnSignUp = document.getElementById('btn-open');
        btnSignUp.style.display = 'none';

        var btnClose = document.getElementById('btn-close');
        btnClose.style.display = 'flex';
    };

    document.getElementById('btn-close').onclick = function openContent() {
        var contentForm = document.getElementById('content-form');
        contentForm.classList.remove('--open');

        var btnSignUp = document.getElementById('btn-open');
        btnSignUp.style.display = 'block';

        var btnClose = document.getElementById('btn-close');
        btnClose.style.display = 'none';
    };
};